// extracted by mini-css-extract-plugin
export var arH1Mobile = "ArDescriptionList-module--ar-h1-mobile--2Huzg";
export var arH2Mobile = "ArDescriptionList-module--ar-h2-mobile--1hVET";
export var arH3Mobile = "ArDescriptionList-module--ar-h3-mobile--3MLld";
export var arH4Mobile = "ArDescriptionList-module--ar-h4-mobile--iChEP";
export var arH5Mobile = "ArDescriptionList-module--ar-h5-mobile--2EMHc";
export var arH1 = "ArDescriptionList-module--ar-h1--3GjOJ";
export var arH2 = "ArDescriptionList-module--ar-h2--BMAn6";
export var arH3 = "ArDescriptionList-module--ar-h3--1QhPZ";
export var arH4 = "ArDescriptionList-module--ar-h4--36sf2";
export var arH5 = "ArDescriptionList-module--ar-h5--18PPq";
export var arParagraphXl = "ArDescriptionList-module--ar-paragraph-xl--BEjOE";
export var arParagraphXlMedium = "ArDescriptionList-module--ar-paragraph-xl-medium--1wSz3";
export var arParagraphBase = "ArDescriptionList-module--ar-paragraph-base--38iDG";
export var arParagraphBaseMedium = "ArDescriptionList-module--ar-paragraph-base-medium--17t_8";
export var arParagraphSmall = "ArDescriptionList-module--ar-paragraph-small--2QCwd";
export var arParagraphSmallMedium = "ArDescriptionList-module--ar-paragraph-small-medium--10W-3";
export var arParagraphXsmall = "ArDescriptionList-module--ar-paragraph-xsmall--2ADLf";
export var arParagraphXsmallMedium = "ArDescriptionList-module--ar-paragraph-xsmall-medium--31xvE";
export var arLinkMobile = "ArDescriptionList-module--ar-link-mobile--3YPGl";
export var arLinkDesktop = "ArDescriptionList-module--ar-link-desktop--2lkJn";
export var arQuote = "ArDescriptionList-module--ar-quote--3Xbzt";
export var arQuoteMobile = "ArDescriptionList-module--ar-quote-mobile--1m4ZV";
export var arGradientLight = "ArDescriptionList-module--ar-gradient-light--X7eFL";
export var arGradientBlue = "ArDescriptionList-module--ar-gradient-blue--2BLXJ";
export var arTransition = "ArDescriptionList-module--ar-transition--2MN4N";
export var container = "ArDescriptionList-module--container--28OdG";
export var description = "ArDescriptionList-module--description--3kCzx";