// extracted by mini-css-extract-plugin
export var arH1Mobile = "ArTextHero-module--ar-h1-mobile--2dNGn";
export var arH2Mobile = "ArTextHero-module--ar-h2-mobile--3rlQl";
export var arH3Mobile = "ArTextHero-module--ar-h3-mobile--2zm_e";
export var arH4Mobile = "ArTextHero-module--ar-h4-mobile--2cH7W";
export var arH5Mobile = "ArTextHero-module--ar-h5-mobile--3N-aI";
export var arH1 = "ArTextHero-module--ar-h1--2V_x4";
export var arH2 = "ArTextHero-module--ar-h2--v5gmW";
export var arH3 = "ArTextHero-module--ar-h3--1JB_n";
export var arH4 = "ArTextHero-module--ar-h4--us4t0";
export var arH5 = "ArTextHero-module--ar-h5--3CtdE";
export var arParagraphXl = "ArTextHero-module--ar-paragraph-xl--1E3Cp";
export var arParagraphXlMedium = "ArTextHero-module--ar-paragraph-xl-medium--1up_9";
export var arParagraphBase = "ArTextHero-module--ar-paragraph-base--2-ZrF";
export var arParagraphBaseMedium = "ArTextHero-module--ar-paragraph-base-medium--2rD95";
export var arParagraphSmall = "ArTextHero-module--ar-paragraph-small--1fQ2e";
export var arParagraphSmallMedium = "ArTextHero-module--ar-paragraph-small-medium--DNLsu";
export var arParagraphXsmall = "ArTextHero-module--ar-paragraph-xsmall--2OrbS";
export var arParagraphXsmallMedium = "ArTextHero-module--ar-paragraph-xsmall-medium--2bI5R";
export var arLinkMobile = "ArTextHero-module--ar-link-mobile--2UkgL";
export var arLinkDesktop = "ArTextHero-module--ar-link-desktop--2pZwV";
export var arQuote = "ArTextHero-module--ar-quote--1zckQ";
export var arQuoteMobile = "ArTextHero-module--ar-quote-mobile--i_bQq";
export var arGradientLight = "ArTextHero-module--ar-gradient-light--3MZrF";
export var arGradientBlue = "ArTextHero-module--ar-gradient-blue--3ctRL";
export var arTransition = "ArTextHero-module--ar-transition--2IJ2q";
export var container = "ArTextHero-module--container--38crS";